import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Hidden,
  IconButton,
  Link,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f9fbff',
    boxShadow: 'none !important'
  },
  toolbar: {
    height: 64,
    maxWidth: '1280px',
    width: '100%',
    margin: '0 auto'
  },
  logo: {
    marginRight: theme.spacing(2),
    maxWidth: '200px'
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.secondary,
    '& + &': {
      marginLeft: theme.spacing(5)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  outlinedButton:{
    [theme.breakpoints.up('md')]:{
      marginRight: '15px'
    }
  },
  itemsContainer:{
    [theme.breakpoints.up('md')]:{
      marginRight: '55px'
    }
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  // const history = useHistory();

  const items = [
    { title: 'About', link: '/#about' },
    { title: 'What I do', link: '/#what-i-do' },
    { title: 'Skills', link: '/#skills' },
    { title: 'Testimontial', link: '/#testimontial' },
    // { title: 'Clients', link: '/#clients' },
    { title: 'Portfolio', link: '/#portfolio' },
    // { title: 'Get in touch', link: '/#get-in-touch' },
  ];

  return (
    <AppBar
      className={clsx(classes.root, className)}
      color="default"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo className={classes.logo} />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown={true}>
          <Box className={classes.itemsContainer}>
          {
            items.map((item) => {
              return (
                <Link
                  key={item.title}
                  className={classes.link}
                  // style={{color: history.location.pathname === item.link? '#49a3da' : null}}
                  component={RouterLink}
                  to={item.link}
                  underline="none"
                  variant="body2"
                >
                  {item.title}
                </Link>    
              );
            })
          }
          </Box>
          <Button
            color="primary"
            component={RouterLink}
            to="/#get-in-touch"
            variant="contained"
            size="medium"
          >
            Get in touch
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
